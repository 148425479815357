<template>
  <base-section
    id="info-alt"
    space="56"
    class="primary"
    style="
      padding-top: 0px !important;
      background: linear-gradient(
        90deg,
        rgb(92, 0, 37) 0%,
        rgb(255, 56, 92) 100%
      );
    "
  >
    <div
      class="triangle-bottomleft"
      :style="
        'border-bottom: ' +
        this.screenCalcTriangleBorderBottom +
        'px solid rgb(170, 0, 76, 0); border-right: ' +
        this.screenWidth +
        'px solid white; margin-bottom: 36px;'
      "
    />
    <base-section-heading style="color: white" title="List of features">
    </base-section-heading>
    <div
      style="
        padding-bottom: 16px;
        max-width: 840px;
        text-align: center;
        margin: auto;
        display: none;
        font-weight: 800;
        font-size: 1rem;
      "
    >
      Designed for your grandma. Suitable for teams of any size and is ideal to
      accept orders | bookings 24/7, generate tasks and keep your customers
      happy. With tentifly you can sell your products, bundles, services and
      even rent!
    </div>
    <div class="perksContainer">
      <v-row v-for="(item, i) in rows" :key="i" style="padding: 16px">
        <v-col
          :lg="item.pictureSrc ? '6' : '12'"
          :xl="item.pictureSrc ? '6' : '12'"
          md="12"
          sm="12"
          xs="12"
          style="margin: auto; padding: 4px"
        >
          <div class="containerHero">
            <div class="itemHero">
              <div
                style="
                  color: white;
                  padding-bottom: 16px;
                  max-width: 840px;
                  text-align: center;
                  margin: auto;
                  font-weight: 600;
                  font-size: 20px;
                "
                class="content"
              >
                {{ item.textSection.header }}
              </div>
              <div
                v-if="item.textSection.description"
                class="description"
                style="
                  color: white;
                  padding-bottom: 16px;
                  max-width: 840px;
                  text-align: center;
                  margin: auto;
                  font-weight: 600;
                "
              >
                {{ item.textSection.description }}
              </div>
              <div class="content" style="padding-bottom: 32px">
                <v-row justify="center">
                  <v-col
                    style="color: rgba(255, 255, 255, 0.85); margin: 8px"
                    :key="t"
                    v-for="(card, t) in item.textSection.features"
                    cols="12"
                    :md="item.pictureSrc ? '4' : '2'"
                    :sm="item.pictureSrc ? '4' : '2'"
                  >
                    <v-container class="pa-0">
                      <v-row justify="center" no-gutters>
                        <v-col
                          class="text-center mb-4"
                          cols="12"
                          justify="center"
                          v-if="card.icon"
                        >
                          <base-icon
                            style="
                              overflow: hidden;
                              height: 6rem;
                              margin: auto;
                              width: 6rem;
                              border-radius: 50%;
                              text-align: center;
                              box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
                                0px 6px 10px 0px rgb(0 0 0 / 14%),
                                0px 1px 18px 0px rgb(0 0 0 / 12%);
                              background-color: #fff;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            {{ card.icon }}
                          </base-icon>
                        </v-col>

                        <v-col
                          v-if="card.title"
                          cols="12"
                          style="text-align: center"
                        >
                          <span
                            style="
                              font-weight: bold;
                              font-size: 16px !important;
                              text-align: center !important;
                            "
                          >
                            {{ card.title }}
                          </span>
                          <div
                            style="color: white"
                            v-if="card.text"
                            v-text="text"
                            space="0"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          lg="6"
          xl="6"
          md="12"
          sm="12"
          xs="12"
          style="margin: auto; padding: 4px"
          v-if="item.pictureSrc"
        >
          <v-row justify="center">
            <v-img
              :max-width="840"
              cover
              :src="item.pictureSrc"
              :key="i + 'app-pic'"
              style="border: 2px solid rgba(255, 255, 255, 0.5)"
            />
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div
      class="triangle-bottomleft"
      :style="
        'border-bottom: ' +
        this.screenCalcTriangleBorderBottom +
        'px solid white; border-right: ' +
        this.screenWidth +
        'px solid transparent; margin-top: 36px;'
      "
    />
  </base-section>
</template>

<style scoped>
.triangle-bottomleft {
  width: 0;
  height: 0;
  border-bottom: 100px solid white;
  border-right: 100px solid transparent;
}
</style>

<script>
import "@/styles/devices.min.css";
export default {
  name: "SectionCarousel",
  computed: {
    maxScreenHeigth() {
      return this.maxScreenWidth / 1.6;
    },
    maxScreenWidth() {
      if (this.screenWidth > 1048) {
        // 88 padding on screen
        return 960;
      } else {
        return 1048 / (1048 / this.screenWidth);
      }
    },
    screenWidth: {
      get() {
        return this.$store.state.screenWidth;
      },
      set(newValue) {
        return this.$store.dispatch("setScreenWidth", newValue);
      },
    },
    screenCalcTriangleBorderBottom() {
      return (100 / 1680) * this.screenWidth; // 1050px for reference
    },
  },
  data() {
    return {
      rows: [
        {
          textSection: {
            header: "Orders & bookings",
            description: "",
            features: [
              {
                icon: "mdi-truck-delivery-outline",
                title: "Delivery planning",
              },
              {
                icon: "mdi-receipt-text-outline",
                title: "Invoicing",
              },
              {
                icon: "mdi-ballot-outline",
                title: "Rentables, products, services, bundles",
              },
              {
                icon: "mdi-sitemap-outline",
                title: "Custom order workflows",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-orders.png",
        },
        {
          textSection: {
            header: "Tentifly assistant",
            description: "",
            features: [
              {
                icon: "mdi-message-badge-outline",
                title: "Customer notifications",
              },
              {
                icon: "mdi-bell-outline",
                title: "Employee notifications",
              },
              {
                icon: "mdi-lightbulb-auto-outline",
                title: "AI suggestions",
              },
              {
                icon: "mdi-typewriter",
                title: "Translations & descriptions",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-product.png",
        },
        {
          textSection: {
            header: "Customers",
            description: "",
            features: [
              {
                icon: "mdi-clipboard-text-clock-outline",
                title: "Order & communication history",
              },

              {
                icon: "mdi-account-heart-outline",
                title: "Automatic customer retention",
              },
              {
                icon: "mdi-account-multiple-check-outline",
                title: "Billing groups",
              },
              {
                icon: "mdi-tag-multiple-outline",
                title: "Custom tagging",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-calendar.png",
        },
        {
          textSection: {
            header: "Inventory",
            description: "",
            features: [
              {
                icon: "mdi-counter",
                title: "Stocktaking & -keeping",
              },
              {
                icon: "mdi-car-key",
                title: "Rentables room planning",
              },
              {
                icon: "mdi-gift-outline",
                title: "Sale bundling",
              },
              {
                icon: "mdi-account-details",
                title: "Unlimited number of clients.",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-room.png",
        },
        {
          textSection: {
            header: "Sale forms",
            description: "",
            features: [
              {
                icon: "mdi-list-box-outline",
                title: "Unlimited sale forms",
              },
              {
                icon: "mdi-go-kart-track",
                title: "Custom form steps",
              },
              {
                icon: "mdi-floor-plan",
                title: "Room graphical layouts",
              },
              {
                icon: "mdi-form-textbox",
                title: "Custom data fields",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app_predictions.png",
        },
        {
          textSection: {
            header: "Dashboard",
            description: "",
            features: [
              {
                icon: "mdi-file-chart-outline",
                title: "Current statistics",
              },
              {
                icon: "mdi-axis-arrow-info",
                title: "Reminders & suggestions",
              },
              {
                icon: "mdi-chart-multiple",
                title: "Business predictions",
              },
              {
                icon: "mdi-view-dashboard-outline",
                title: "Personal dashboard",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-form.png",
        },
        {
          textSection: {
            header: "Employees",
            description: "",
            features: [
              {
                icon: "mdi-account-hard-hat-outline",
                title: "Unlimited employees",
              },
              {
                icon: "mdi-lock-outline",
                title: "Detailed access management",
              },
              {
                icon: "mdi-briefcase-outline",
                title: "Worksheet planning",
              },
              {
                icon: "mdi-bike-fast",
                title: "Statistics and motivation system",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/app-dashboard.png",
        },
        {
          textSection: {
            header: "Unique extras",
            description: "",
            features: [
              {
                icon: "mdi-store-outline",
                title: "No-setup e-shop page",
              },
              {
                icon: "mdi-toy-brick-outline",
                title: "Webpage Tentifly plugin",
              },
              {
                icon: "mdi-shopping-outline",
                title: "Tentifly marketplace spot",
              },
              {
                icon: "mdi-note-text-outline",
                title: "Pre-made sale form templates",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/online_shops.png",
        },
        {
          textSection: {
            header: "Marketing",
            description: "",
            features: [
              {
                icon: "mdi-text-box-edit-outline",
                title: "Auto translations & descriptions",
              },
              {
                icon: "mdi-image-marker-outline",
                title: "Premade marketing templates",
              },
              {
                icon: "mdi-qrcode-scan",
                title: "Easy sale form sharing",
              },
              {
                icon: "mdi-radar",
                title: "Marketing insights",
              },
            ],
          },
          pictureSrc: "https://cdn.tentifly.com/png/ongline_catalog.png",
        },
      ],
    };
  },
};
</script>
